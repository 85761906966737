import { AnimateOnScroll } from 'animate-on-scroll-framer'
import React from 'react'
import { Link } from 'react-router-dom'

const NAV_LINKS = [
  {
    name: 'About',
    linkPath: '#about',
  },
  {
    name: 'Features',
    linkPath: '#features',
  },
  {
    name: 'Tokenomics',
    linkPath: '#token',
  },
  {
    name: 'Roadmap',
    linkPath: '#roadmap',
  },
  {
    name: 'Whitepaper',
    linkPath: 'https://yieldstone.gitbook.io/yieldstone-whitepaper',
  },
  {
    name: 'CoinMarketCap',
    linkPath: 'https://coinmarketcap.com/currencies/yieldstone/',
  },
  {
    name: 'CoinGecko',
    linkPath: 'https://www.coingecko.com/en/coins/yieldstone',
  },
  {
    name: 'Medium',
    linkPath: 'https://medium.com/@yieldstonecrypto',
  },
  ,
]

export default function Footer() {
  return (
    <div>
      <div className='container mx-auto md:px-10 px-5 md:py-20 py-10'>
        <AnimateOnScroll animation='flipIn'>
          <h3 className='font-medium text-[20px] text-[#969696] text-center'>
            Join Us
          </h3>
        </AnimateOnScroll>
        <AnimateOnScroll animation='flipIn'>
          <h3 className='font-medium text-[40px] text-center'>
            Join YieldStone Community <br /> Today!
          </h3>
        </AnimateOnScroll>
        <div>
          <div className='grid md:grid-cols-2 gap-8 grid-cols-1 lg:px-20 md:px-10 mt-10'>
            <div className=' '>
              <AnimateOnScroll animation='flipIn'>
                <h5>Telegram Group</h5>
              </AnimateOnScroll>
              <AnimateOnScroll animation='flipIn'>
                <p className='font-light mt-2 md:mb-16 mb-6'>
                  Join our Telegram group to connect with a dynamic community of
                  investors and the YieldStone team. This platform provides
                  instant updates, discussions, and direct feedback from our
                  staff.
                </p>
              </AnimateOnScroll>
              <AnimateOnScroll animation='flipIn'>
                <a
                  href='https://t.me/Yieldstone'
                  target='_blank'
                  className='xxl:mt-[-0.25rem] xl:mt-4 lg:-mt-2 md:mt-4 mt-0 bg-[#070324] text-white px-10 py-3 rounded-full  font-medium hover:bg-[#070324] hover:border hover:border-white hover:text-white'
                >
                  Telegram
                </a>
              </AnimateOnScroll>
            </div>
            <div className=' '>
              <AnimateOnScroll animation='flipIn'>
                <h5>Twitter</h5>
              </AnimateOnScroll>
              <AnimateOnScroll animation='flipIn'>
                <p className='font-light mt-2 mb-4'>
                  Our Twitter feed is a vital channel for real-time updates,
                  engaging content, & direct interactions. It's the go-to place
                  to stay informed about new offerings, market insights, &
                  company news. Engage with us and the broader community to
                  enhance your investment journey with YieldStone.
                </p>
              </AnimateOnScroll>
              <AnimateOnScroll animation='flipIn'>
                <div className='mt-5'>
                  <a
                    href='https://x.com/yieldstone'
                    target='_blank'
                    className=' mt-4 bg-[#070324] text-white px-10 py-3 rounded-full  font-medium hover:bg-[#070324] hover:border hover:border-white hover:text-white'
                  >
                    Twitter
                  </a>
                </div>
              </AnimateOnScroll>
            </div>
          </div>
        </div>
      </div>
      <div className='h-5 bg-[#868686]'></div>
      <div className='h-9 bg-[#4CDDA3]'></div>
      <div className='bg-[#070324] text-white'>
        <div className='container mx-auto md:px-10 px-5 md:py-10 py-5'>
          <div className='grid md:grid-cols-2 grid-cols-1 gap-10 lg:mt-20 md:mt-14 mt-8 py-8 border-b border-[FFFFFF1A]'>
            <div className=''>
              <img
                src='/images/logo.png'
                alt='logo'
                width={233}
                height={42}
              />
              <p className='text-[#8D8D8D] font-normal mt-2'>
                Unleashing the power of blockchain for real estate investment.
              </p>
            </div>
            <div className='flex items-start justify-around gap-10'>
              <div>
                <h6>Company</h6>
                <ul className='mt-4'>
                  {NAV_LINKS.map((item, index) => (
                    <li
                      key={index}
                      className=' text-[#8D8D8D] font-light hover:text-white transition-all  hover:translate-x-5 '
                    >
                      <a href={item.linkPath}>{item.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h6>Socials</h6>
                <div className='flex items-center gap-5 mt-5'>
                  <a href='https://twitter.com/yieldstone'>
                    <span className='w-10 h-10 hover:bg-white transetion-all hover:text-black flex items-center justify-center rounded-full'>
                      <svg
                        width='17'
                        height='17'
                        viewBox='0 0 17 17'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.57282 6.96062L15.5609 0H14.1419L8.94247 6.0438L4.78972 0H0L6.27979 9.1393L0 16.4386H1.41905L6.90977 10.0561L11.2954 16.4386H16.0851L9.57247 6.96062H9.57282ZM7.62923 9.21982L6.99296 8.30975L1.93036 1.06824H4.10995L8.19552 6.91237L8.8318 7.82244L14.1426 15.4189H11.963L7.62923 9.22017V9.21982Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </a>
                  <a href='https://t.me/Yieldstone'>
                    <span className='w-10 h-10 hover:bg-white transetion-all hover:text-black flex items-center justify-center rounded-full'>
                      <svg
                        width='17'
                        height='15'
                        viewBox='0 0 17 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M1.16866 6.4574C5.73205 4.34056 8.77503 2.945 10.2976 2.27074C14.6448 0.345581 15.5481 0.0111605 16.1369 0.000117741C16.2664 -0.00231099 16.5559 0.031858 16.7435 0.19389C16.9018 0.330707 16.9454 0.515527 16.9663 0.645244C16.9871 0.774961 17.0131 1.07046 16.9924 1.30136C16.7569 3.93675 15.7375 10.3322 15.2189 13.2838C14.9995 14.5328 14.5674 14.9516 14.1492 14.9926C13.2401 15.0816 12.5498 14.3529 11.6694 13.7384C10.2916 12.7769 9.5133 12.1783 8.17597 11.24C6.63045 10.1556 7.63235 9.55964 8.51313 8.58562C8.74364 8.33071 12.7489 4.45188 12.8264 4.10001C12.8361 4.05601 12.8451 3.89197 12.7536 3.80535C12.6621 3.71873 12.527 3.74835 12.4295 3.77191C12.2913 3.8053 10.0904 5.35418 5.82663 8.41854C5.2019 8.87529 4.63604 9.09783 4.12904 9.08617C3.57012 9.07331 2.49499 8.7497 1.69573 8.47308C0.715411 8.1338 -0.063727 7.95442 0.00411841 7.37821C0.0394565 7.07809 0.427637 6.77115 1.16866 6.4574Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </a>
                  <a href='https://medium.com/@yieldstonecrypto'>
                    <span className='w-10 h-10 hover:bg-white transetion-all hover:text-black flex items-center justify-center rounded-full'>
                      <svg
                        width='18'
                        height='15'
                        viewBox='0 0 18 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.13227 2.91379C2.15463 2.69428 2.06901 2.47473 1.90529 2.32586L0.227019 0.301434V0H5.44427L9.47818 8.84569L13.0246 0H18V0.301434L16.5636 1.67831C16.4408 1.77135 16.3776 1.92761 16.4036 2.08021V12.2022C16.3776 12.3548 16.4408 12.5111 16.5636 12.6042L17.9665 13.981V14.2825H10.9072V13.981L12.3622 12.5707C12.5036 12.4293 12.5036 12.3845 12.5036 12.1687V3.98928L8.45856 14.2564H7.91153L3.2078 3.98928V10.8701C3.16678 11.1603 3.26355 11.4506 3.46829 11.6589L5.35869 13.9513V14.2528H0V13.955L1.89044 11.6589C2.09137 11.4506 2.18437 11.1566 2.13227 10.8701V2.91379Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <p className='text-[#27D690] text-center mt-8'>
            Copyright © 2024 YieldStone.. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  )
}
